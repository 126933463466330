import React, { useState } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import SatisfiedUsersSection from 'components/reviews/SatisfiedUsersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';
import { tablet, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import Logo from 'assets/icons/logo.svg';
import BurgerMenuButton from 'components/reviews/BurgerMenuButton';
import HeaderNav from 'components/reviews/HeaderNav';
import BurgerMenu from 'components/reviews/BurgerMenu';
import CardsSection from 'components/reviews/CardsSection';

const Reviews = () => {
  const { isTablet } = useQuery();
  // useScrollToTop(0);

  return (
    <>
      <Seo
        title="Nord Pilates Reviews"
        name="Nord Pilates Reviews"
        description="Discover what over 15 million satisfied users have to say about their body transformations with Nord Pilates."
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <CardsSection />
      <SatisfiedUsersSection />
      <SuccessStoriesSection />
      <LCFooter
        disclaimerText={['© 2025 Nord Pilates. All rights reserved.']}
      />
    </>
  );
};

export default Reviews;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 4.5rem;
  position: sticky;
  width: 100%;
  max-width: 90rem;
  padding: 1.5rem 1rem;
  z-index: 99;
  background-color: #fff;
  top: 0;
`;

const LogoStyled = styled(Logo)`
  width: 7.877rem;
  height: 1.625rem;
  @media ${tablet} {
    width: 5.9275rem;
    height: 1.5rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  div {
    position: absolute;
    right: 0;
    top: -3.5px;
    display: flex;
  }
`;

const HeaderWrapper = styled.div`
  background: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  min-height: 24px;
`;
